<template>
	<div class="position-relative w-100">
		<div class="position-relative">
			<VideoPlyer
				v-if="loading"
				:uid="uid"
				:is_evidence="is_evidence"
				:text_download="text_download"
				:url_max_quality="url_max_quality"
			/>
			<div class="preloader" v-else>
				<div class="loader"></div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapState } from "vuex"
import { getUrlMaxQuaility } from "../videolibrary/service.videolibrary"
import VideoPlyer from "./VideoPlyer.vue"
import store from "@/store"

export default {
	components: {
		VideoPlyer,
	},
	name: "VideoCloudflare",
	props: {
		uid: {
			type: String,
			default: null,
		},
		text_download: {
			type: String,
		},
	},
	data() {
		return {
			loading: false,
			url_max_quality: "",
			is_evidence: false,
		}
	},
	async created() {
		this.getUrlMaxQuaility()
		if (this.imagesVideoteca.length) {
			if (this.imagesVideoteca[this.indexImage].evidence) {
				this.is_evidence = this.imagesVideoteca[this.indexImage].evidence
			}
		}
	},
	mounted() {},
	computed: {
		...mapState("video", ["playbackRates"]),
		...mapState("header_book", [
			"images",
			"imagesVideoteca",
			"openModal",
			"indexImage",
			"videoteca",
			"toggleOpenGallery",
			"id_specimen",
		]),
	},
	methods: {
		async getUrlMaxQuaility() {
			const { data } = await getUrlMaxQuaility(this.uid)
			this.url_max_quality = data.url_max_quality
			this.loading = true
		},
	},
	async beforeDestroy() {
		await store.dispatch("video/INIT_PLAYRATES")
	},
}
</script>
<style>
.preloader {
	position: absolute;
	inset: 0;
	display: flex;
	justify-content: center;
	align-items: center;
	background: black;
	border-radius: 4px;
	height: 500px;
}
.loader {
	border: 6px solid #f3f3f3;
	border-top: 6px solid var(--primary);
	border-radius: 50%;
	width: 60px;
	height: 60px;
	animation: spin 2s linear infinite;
}
@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}
.bg-black {
	background: black !important;
}
</style>
